import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    route: "/",
    profile: null,
    error: null,
    jwt: null,
    pbiJwt: null,
    isJwtRequested: false,
    photo: null,
  },
  reducers: {
    requestPbiJwt: () => {},
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.profile = action.payload;
    },
    setRoute: (state, action) => {
      state.route = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
    setAuthFail: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    requestJWT: (state) => {
      state.isJwtRequested = true;
    },
    setJWT: (state, action) => {
      state.jwt = action.payload;
      state.isJwtRequested = false;
    },
    setPBI: (state, action) => {
      state.pbiJwt = action.payload;
    },
  },
});

export const authSelector = (state) => state.auth;

export const {
  requestPbiJwt,
  setUser,
  setPhoto,
  setAuthFail,
  setJWT,
  setPBI,
  requestJWT,
  setRoute,
} = authSlice.actions;
export default authSlice.reducer;
