// import logo from "./logo.svg";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { authSelector } from "./components/auth/auth.redux";
//import { apiSelector } from "./utils/api/api.redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  let envVal = "https://spider-dev.3m.com/"
  //const api = useSelector(apiSelector);
  const { photo, profile } = auth;

  if (window.location.hostname === "portal.dev.spider.cld.3m.com") {
    envVal = "https://spider-dev.3m.com/"
  }else 
  if (window.location.hostname === "portal.qa.spider.cld.3m.com") {
    envVal = "https://spider-qa.3m.com/"
  }else
  if (window.location.hostname === "portal.spider.cld.3m.com") {
    envVal = "https://spider.3m.com/"
  }

  // const {
  //   isLoading,
  //   isAuthenticated,
  //   error,
  //   user,
  //   loginWithRedirect,
  //   getAccessTokenSilently,
  // } = useAuth0();

  // useEffect(() => {
  //   if (!isAuthenticated && !isLoading) {
  //     const fn = async () => {
  //       await loginWithRedirect();
  //     };
  //     fn();
  //   }
  //   if (isAuthenticated && !auth.isAuthenticated && !isLoading && user) {
  //     dispatch(setUser(user));
  //   }
  // }, [
  //   auth.isAuthenticated,
  //   isAuthenticated,
  //   isLoading,
  //   user,
  //   dispatch,
  //   loginWithRedirect,
  // ]);

  // useEffect(() => {
  //   if (auth.isJwtRequested) {
  //     const fn = async (f) => {
  //       const token = await getAccessTokenSilently();
  //       console.log("jwttoken",token)
  //       dispatch(setJWT(token));
  //     };
  //     fn();
  //   }
  // }, [auth.isJwtRequested, dispatch, getAccessTokenSilently]);

  // if (isLoading) {
  //   return <LoadingAuthComponent />;
  // }
  // if (error) {
  //   dispatch(setAuthFail(error));
  //   return <ErrorAuthComponent error />;
  // }
  // if (isAuthenticated) {
  return (
    <div>
      <h2>
        There is a new release of Spider available:{" "}
        <a href={envVal}> here </a> .  Please update your
        bookmarked links to <a href={envVal}> here </a>{" "}
      </h2>
      {/* <StyledEngineProvider injectFirst>
          <PersistentDrawerLeft
            navigation={<NavigationComponent />}
            profile={{ photo, fullName: profile ? profile.name : null }}
          >
            <AppRoutes />
          </PersistentDrawerLeft>
          <Snackbar
            open={api.error ? true : false}
            onClose={() => dispatch(setError(null))}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {api.error}
            </Alert>
          </Snackbar>
        </StyledEngineProvider> */}
    </div>
  );
  // } else {
  //   return <UnauthenticatedComponent />;
  // }
}

export default App;
