import axios from "axios";
import jwt from "jwt-decode";

export const getDecodedAccessToken = (token) => {
  try {
    return jwt(token);
  } catch (Error) {
    return null;
  }
};

const extractANumber = (username) => {
  return username ? username.substr(0, username.indexOf("@")) : "";
};

export function getANumber(accessToken) {
  const tokenDecode = getDecodedAccessToken(accessToken);
  const aNumberFromToken = extractANumber(tokenDecode["https://azuread#upn"]);
  return aNumberFromToken;
}

export function getGraphToken(accessToken) {
  const tokenDecode = getDecodedAccessToken(accessToken);
  const azureGraphToken = tokenDecode["https://azuread#ad_access_token"];
  return azureGraphToken;
}

const axiosInstance = new axios.create({
  headers: {
    // Authorization: `Bearer ${accessToken}`,
    // user_id: `${aNumberFromToken}`,
    //   "Access-Control-Allow-Origin": "*",
    //   "Access-Control-Allow-Headers":
    //     "Origin, X-Requested-With, Content-Type, Accept",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    Expires: "0",
  },

  baseURL: `${process.env.REACT_APP_API_URL}`,
});

const authorizedInstance = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
  axiosInstance.defaults.headers.common["user_id"] = getANumber(token);

  return axiosInstance;
};

export default authorizedInstance;
