import { take, takeLatest } from "@redux-saga/core/effects";
import { all, call, put, select } from "redux-saga/effects";
import authorizedInstance from "../../utils/axios.service";
import { getDecodedAccessToken } from "../../utils/axios.service";
import { requestJWT, setJWT, authSelector } from "../auth/auth.redux";

import {
  startSalesSearch,
  startInfoSearch,
  startPlantSearch,
  startNodeSearch,
  successInfoSearch,
  setGlobalFilters,
  setStandardFilters,
  setStandardExpandedFilters,
  applyStandardFilters,
  applyGlobalFilters,
  setQueryHistory,
  setNotification,
  apiSelector,
  setSales,
  setLoading,
  toRedirect,
  setPlants,
  setNodes,
  getAllFilters,
  deleteStandardFilters,
  deleteGlobalFilters,
  getUserCompetedQuery,
  setUserCompetedQuery,
} from "./api.redux";

export function* getSalesSummaryInfoAsync({ payload }) {
  try {
    const { matlList, searchStrings, years } = payload;
    // const searchString = true ? `, "SearchStrings": "True"` : "";

    const searchString = searchStrings ? `, "SearchStrings": "True"` : "";
    var substr = /^SKU-/; // This is for prefixes only

    if (matlList) {
      for (let i = 0; i < matlList.length; i++) {
        matlList[i] = matlList[i].replace(substr, "");
      }
    }

    const data = `{"Query": "SearchSummary", "Skus": ${JSON.stringify(
      matlList
    )} ${searchString}, "year": ${years}}`;
    const apiRequest = yield call(
      (yield call(getAuthInstance)).post,
      `queryNeptune`,
      data
    );

    yield put(
      successInfoSearch({
        materials: apiRequest.data.skusToSearchOn,
        years: years,
      })
    );
  } catch (e) {
    yield put(
      setNotification({
        isError: true,
        messagee: e.response.data.Error,
        isSilent: false,
      })
    );
  }
}

export function* getStandardFiltersAsync() {
  try {
    const apiRequest = yield call(
      (yield call(getAuthInstance)).post,
      `dynamoGetUserFilters`
    );

    apiRequest.data =
      apiRequest.data && apiRequest.data === {}
        ? []
        : apiRequest.data.length > 0
        ? apiRequest.data
        : [];

    yield put(setStandardExpandedFilters(apiRequest.data));

    const { params } = yield select(apiSelector);

    const filtersFilteredByRequest = apiRequest.data
      .filter(
        (d) =>
          params.materials
            .split(" ")
            .filter((p) => d.Inputs.map((i) => i.id.split("-")[1]).includes(p))
            .length === params.materials.split(" ").length &&
          params.materials.split(" ").length === d.Inputs.length &&
          d.year === params.year.toString()
      )
      .map((m) => m.Exclude)
      .flat();

    yield put(setStandardFilters(filtersFilteredByRequest));
  } catch (e) {
    yield put(
      setNotification({
        isError: true,
        messagee: e.response.data.Error,
        isSilent: false,
      })
    );
  }
}

export function* getGlobalFiltersAsync() {
  try {
    const apiRequest = yield call(
      (yield call(getAuthInstance)).post,
      `dynamoGetUserInfo`
    );
    // TODO: check api respond structure to identify a proper respond from server
    apiRequest.data = apiRequest.data.GlobalFilter
      ? apiRequest.data.GlobalFilter
      : [];

    yield put(setGlobalFilters(apiRequest.data));
  } catch (e) {
    yield put(
      setNotification({
        isError: true,
        messagee: e.response.data.Error,
        isSilent: false,
      })
    );
  }
}

export function* queryNeptuneAsync(payload) {
  try {
    let { queryType, skus, globalFilters, searchStrings, localFilters, years } =
      payload;
    let data = "";
    let searchString = "";
    let formattedSkuList = "";

    let skusNot =
      globalFilters.length > 0
        ? `, "skusNotToInclude":${JSON.stringify(globalFilters)}`
        : "";
    let fgsNot =
      localFilters.length > 0
        ? `, "fgIdsNotToInclude":${JSON.stringify(localFilters)}`
        : "";
    let year = `,"year":"${JSON.stringify(years)}"`;
    let yearSkusAndFgsNot = `${year} ${skusNot} ${fgsNot}`;

    switch (queryType) {
      case "SalesSummary":
        searchString = searchStrings ? `, "SearchStrings": "True"` : "";
        data = `{"Query": "SalesSummary", "Skus": ${JSON.stringify(
          skus ? skus : searchStrings
        )} ${searchString} ${yearSkusAndFgsNot}}`;
        break;
      case "PlantView":
        formattedSkuList = JSON.stringify(skus).includes("[")
          ? JSON.stringify(skus)
          : `[${JSON.stringify(skus)}]`;
        data = `{"Query": "PlantView", "Skus": ${formattedSkuList} ${skusNot} ${fgsNot}}`;
        break;
      case "NodeGraph":
        let formattedList = JSON.stringify(skus).includes("[")
          ? JSON.stringify(skus)
          : `[${JSON.stringify(skus)}]`;
        formattedSkuList = JSON.stringify(searchStrings).includes("[")
          ? JSON.stringify(searchStrings)
          : `[${JSON.stringify(searchStrings)}]`;
        data = `{"Query": "NodeGraph", "Skus": ${formattedList}, "FinishedGoods": ${
          searchStrings.length > 0 ? formattedSkuList : null
        } ${skusNot} ${fgsNot}}`;
        break;
      case "BomExplosion":
        formattedSkuList = JSON.stringify(skus).includes("[")
          ? JSON.stringify(skus)
          : `[${JSON.stringify(skus)}]`;
        data = `{"Query": "BomExplosion", "FinishedGoods": ${formattedSkuList} ${skusNot} }`;
        break;
      default:
        break;
    }

    const apiRequest = yield call(
      (yield call(getAuthInstance)).post,
      `queryNeptuneAsync`,
      data
    );
    yield put(setQueryHistory({ data, query: apiRequest.data }));
    return apiRequest.data;
  } catch (e) {
    return { QueryID: null };
  }
}

export function* neptuneGetAsyncResult(queryId, retryCount = 0) {
  try {
    const apiRequest = yield call(
      (yield call(getAuthInstance)).get,
      `neptuneGetAsyncResult?QueryID=${queryId}`
    );

    //TODO: hooks for loaders and error handlings
    if (apiRequest.status === 204) {
      if (retryCount === 8) {
        yield put(
          setNotification({
            isError: false,
            messagee: `Error completing request. Look for a notification when it completes.`,
            isSilent: false,
          })
        );
        return null;
      }
      retryCount++;

      yield put(
        setNotification({
          isError: false,
          messagee: `Data not ready yet. Retry attempt ${retryCount} of 8...`,
          isSilent: false,
        })
      );

      let backoffTime =
        Math.pow(2, retryCount) * 2000 < 30000
          ? Math.pow(2, retryCount) * 2000
          : 30000;

      const awaitDelay = () =>
        new Promise((resolve) => {
          setTimeout(resolve, backoffTime);
        });

      yield call(awaitDelay);
      return yield call(neptuneGetAsyncResult, queryId, retryCount);
    }

    return apiRequest.data;
  } catch {
    yield put(
      setNotification({
        isError: true,
        messagee: `Error completing request.`,
        isSilent: false,
      })
    );
    return null;
  }
}

export function* getAllFiltersAsync() {
  yield all([call(getGlobalFiltersAsync), call(getStandardFiltersAsync)]);
}

export function* loadMaterialInfoWithFilters(payload) {
  yield all([
    call(getSalesSummaryInfoAsync, payload),
    call(getGlobalFiltersAsync),
    call(getStandardFiltersAsync),
  ]);

  const { materials, globalFilters, standardFilters } = yield select(
    apiSelector
  );

  const globalFiltersId =
    globalFilters.length > 0 ? globalFilters.map((m) => m.id) : globalFilters;
  const standardFiltersId =
    standardFilters.length > 0
      ? standardFilters.map((e) => e.id)
      : standardFilters;
  return {
    materials,
    globalFilters: globalFiltersId,
    standardFilters: standardFiltersId,
  };
}

export function* getLocalGlobalSales({ payload }) {
  const { matlList, searchStrings, years } = payload;
  let { queryId } = payload;

  yield put(setLoading(true));

  if (!queryId) {
    const { materials, globalFilters, standardFilters } = yield call(
      loadMaterialInfoWithFilters,
      { payload }
    );

    if (materials.length === 0) {
      yield put(setLoading(false));
      return;
    }

    const { QueryID } = yield call(queryNeptuneAsync, {
      queryType: "SalesSummary",
      skus: searchStrings? matlList: materials.map((m) => m.skuToSearchOnActingMaterialNumber),
      searchStrings,
      globalFilters,
      localFilters: standardFilters,
      years: years[0],
    });

    queryId = QueryID;
  }

  if (queryId === null) {
    yield put(setLoading(false));
    return;
  }

  const data = yield call(neptuneGetAsyncResult, queryId);
  yield put(setSales(data));
  yield put(setLoading(false));
}

export function* getPlantDataAsync({ payload }) {
  const { years } = payload;
  let { queryId } = payload;

  yield put(setLoading(true));

  if (!queryId) {
    const { materials, globalFilters, standardFilters } = yield call(
      loadMaterialInfoWithFilters,
      { payload }
    );

    if (materials.length === 0) {
      yield put(setLoading(false));
      return;
    }

    const { QueryID } = yield call(queryNeptuneAsync, {
      queryType: "PlantView",
      skus: materials.map((m) => m.skuToSearchOnId),
      searchStrings: [],
      globalFilters,
      localFilters: standardFilters,
      years: years[0],
    });

    queryId = QueryID;
  }

  if (queryId === null) {
    yield put(setLoading(false));
    return;
  }

  const data = yield call(neptuneGetAsyncResult, queryId);
  yield put(setPlants(data));
  yield put(setLoading(false));
  yield put(toRedirect(true));
}

export function* getNodeDataAsync({ payload }) {
  let { skus, finishedGoods, years } = payload;
  let { query } = payload;
  let queryId;

  yield put(setLoading(true));

  if (!(query && query.QueryID)) {
    const { globalFilters, standardFilters } = yield select(apiSelector);

    const { QueryID } = yield call(queryNeptuneAsync, {
      queryType: "NodeGraph",
      skus: skus.map((m) => m.skuToSearchOnId),
      searchStrings: finishedGoods.map((p) => p.id),
      globalFilters: globalFilters.map((f) => f.id),
      localFilters: standardFilters.map((f) => f.id),
      years: years[0],
    });

    queryId = QueryID;
  } else {
    queryId = query.QueryID;
  }

  if (queryId === null) {
    yield put(setLoading(false));
    return;
  }

  const { elements } = yield call(neptuneGetAsyncResult, queryId);

  if (!skus && query && query.QueryID) {
    skus = query.RawMaterials.map((r) => ({
      skuToSearchOnId: r,
      skuToSearchOnDescription: elements.nodes.find((n) => n.data.id === r).data
        .ITEM_NAME,
    }));
    finishedGoods = query.FinishedGoods.map((r) => ({
      id: r,
      name: elements.nodes.find((n) => n.data.id === r).data.ITEM_NAME,
    }));
  }

  yield put(setNodes({ elements, params: { skus, finishedGoods } }));
  yield put(setLoading(false));
  yield put(toRedirect(true));
}

export function* getSalesSummary(payload) {
  yield put(setLoading(true));
  yield call(getSalesSummaryInfoAsync, payload);
  yield put(setLoading(false));
}

export function* applyStandardFiltersAsync() {
  const { standardFilters, materials, years } = yield select(apiSelector);

  yield call((yield call(getAuthInstance)).post, `saveUserFilter`, {
    Filter: {
      Inputs: materials.map((f) => ({
        id: f.skuToSearchOnId,
        name: f.skuToSearchOnDescription,
        actingMatlNum: f.skuToSearchOnMmmId,
      })),
      Exclude: standardFilters,
      year: `${years[0]}`,
    },
  });

  yield put(
    startSalesSearch({
      years,
      matlList: materials.map((m) => m.skuToSearchOnMmmId),
      searchList: null,
      searchStrings: null,
    })
  );
}

export function* applyGlobalFilterAsync({ payload }) {
  yield put(
    setNotification({
      isError: false,
      messagee: "In progress to apply a global filters. Please wait.",
      isSilent: false,
    })
  );

  yield call(getGlobalFiltersAsync);

  let { years, globalFilters } = yield select(apiSelector);

  try {
    const filters = [
      ...globalFilters.filter((g) =>
        payload.filters.some((f) => f.skuToSearchOnId !== g.id)
      ),
      ...payload.filters.map((f) => ({
        id: f.skuToSearchOnId,
        name: f.skuToSearchOnDescription,
        actingMatlNum: f.skuToSearchOnMmmId,
        year: `${years[0]}`,
      })),
    ];

    yield call((yield call(getAuthInstance)).post, `saveUserFilter`, {
      GlobalFilter: filters,
    });

    yield put(
      setNotification({
        isError: false,
        messagee:
          "Global filters have been applied. To view or remove go to the filters page.",
        isSilent: false,
      })
    );

    yield put(setGlobalFilters(filters));

    if (payload.toRedirect) {
      yield put(applyStandardFilters());
    }
  } catch (e) {
    yield put(
      setNotification({
        isError: false,
        messagee: "Global filters could not be applied.",
        isSilent: false,
      })
    );
  }
}

export function* deleteStandardFiltersAsync({ payload }) {
  yield put(
    setNotification({
      isError: false,
      messagee: "In progress to remove a standard filters. Please wait.",
      isSilent: false,
    })
  );

  yield call((yield call(getAuthInstance)).post, `deleteUserFilter`, {
    Filters: payload,
  });

  yield call(getStandardFiltersAsync);
}

export function* deleteGlobalFiltersAsync({ payload }) {
  yield put(
    setNotification({
      isError: false,
      messagee: "In progress to remove a global filters. Please wait.",
      isSilent: false,
    })
  );

  yield call((yield call(getAuthInstance)).post, `saveUserFilter`, {
    GlobalFilter: payload.filtersToDelete,
  });

  yield put(setGlobalFilters(payload.filtersToDelete));

  yield put(
    setNotification({
      isError: false,
      messagee: "Global filters were succesfully removed!",
      isSilent: false,
    })
  );

  if (payload.toRedirect) {
    yield put(applyStandardFilters());
  }
}

export function* getUserCompetedQueryAsync() {
  const queryHistory = yield call(
    (yield call(getAuthInstance)).post,
    `neptuneGetUserCompletedAsyncQueryIds`
  );

  yield put(setUserCompetedQuery(queryHistory.data));
}

export function* getAuthInstance() {
  let { jwt } = yield select(authSelector);

  if (
    !jwt ||
    (jwt && getDecodedAccessToken(jwt)
      ? getDecodedAccessToken(jwt).exp < new Date() / 1000
      : true)
  ) {
    yield put(requestJWT());
    const { payload: token } = yield take(setJWT);
    jwt = token;
  }
  return authorizedInstance(jwt);
}

export function* apiSagas() {
  yield all([
    takeLatest(startSalesSearch, getLocalGlobalSales),
    takeLatest(startInfoSearch, getSalesSummary),
    takeLatest(startPlantSearch, getPlantDataAsync),
    takeLatest(startNodeSearch, getNodeDataAsync),
    takeLatest(applyStandardFilters, applyStandardFiltersAsync),
    takeLatest(deleteStandardFilters, deleteStandardFiltersAsync),
    takeLatest(deleteGlobalFilters, deleteGlobalFiltersAsync),
    takeLatest(applyGlobalFilters, applyGlobalFilterAsync),
    takeLatest(getAllFilters, getAllFiltersAsync),
    takeLatest(getUserCompetedQuery, getUserCompetedQueryAsync),
  ]);
}
