import { PublicClientApplication, EventType } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const msalConfig = {
  auth: {
    clientId: "675840c2-56ab-4846-9825-a580748f106c",
    authority:
      "https://login.microsoftonline.com/facac3c4-e2a5-4257-af76-205c8a821ddb",
    redirectUri: `${window.location.origin}/callback`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

var request = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
  forceRefresh: false,
};

//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
// msalInstance.handleRedirectPromise().then();
if (msalInstance.handleRedirectPromise()) {
  msalInstance.handleRedirectPromise().then();
}

export const callADDforToken = async () => {
  const account = msalInstance.getActiveAccount();
  const token = await msalInstance
    .acquireTokenSilent({ ...request, account })
    .catch(async () => {
      // fallback to interaction when silent call fails
      return await msalInstance.acquireTokenRedirect(request).catch((error) => {
        console.log(error);
      });
    });

  return token;
};

export default msalInstance;
