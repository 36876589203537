import { createSlice } from "@reduxjs/toolkit";

const wipeResults = (state) => {
  state.sales = null;
  state.standardFilters = [];
  state.globalFilters = [];
  state.materials = [];
  state.plants = null;
  state.divisions = null;
};

export const authSlice = createSlice({
  name: "api",
  initialState: {
    isLoading: false,
    notification: { isError: false, messagee: null, isSilent: true },
    // error: null,
    toRedirect: false,
    params: { year: new Date().getFullYear(), keywords: "", materials: "" },
    globalFilters: [],
    standardFilters: [],
    standardFiltersExpanded: [],
    filtersPendingUpdate: false,
    queryHistory: [],
    completedQuery: [],
    materials: [],
    years: [],
    sales: null,
    plants: null,
    divisions: null,
  },
  reducers: {
    startSalesSearch: (state) => {
      wipeResults(state);
    },
    startInfoSearch: (state) => {
      wipeResults(state);
    },
    startPlantSearch: (state) => {
      wipeResults(state);
    },
    startNodeSearch: (state) => {
      state.plants = null;
    },
    getAllFilters: () => {},
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    toRedirect: (state, action) => {
      state.toRedirect = action.payload;
    },
    setParams: (state, action) => {
      state.params = { ...state.params, ...action.payload };
    },
    successInfoSearch: (state, action) => {
      state.materials = action.payload.materials;
      state.years = action.payload.years;
    },
    applyGlobalFilters: () => {},
    setGlobalFilters: (state, action) => {
      state.globalFilters = action.payload;
    },
    deleteGlobalFilters: () => {},
    applyStandardFilters: (state) => {
      state.filtersPendingUpdate = false;
    },
    setStandardFilters: (state, action) => {
      state.standardFilters =
        action.payload.length > 0
          ? [
              ...state.standardFilters.filter(
                (f) => !action.payload.some((nf) => nf.id === f.id)
              ),
              ...action.payload,
            ]
          : [];
    },
    setStandardExpandedFilters: (state, action) => {
      state.standardFiltersExpanded = action.payload;
    },
    deleteStandardFilters: () => {},
    clearStandardFilters: (state, action) => {
      state.standardFilters =
        action.payload.length > 0
          ? state.standardFilters.filter(
              (f) => !action.payload.some((p) => p.id === f.id)
            )
          : [];
      state.filtersPendingUpdate = true;
    },
    setQueryHistory: (state, action) => {
      state.queryHistory = [...state.queryHistory, action.payload];
    },
    setSales: (state, action) => {
      state.sales = action.payload;
    },
    setPlants: (state, action) => {
      state.plants = action.payload;
    },
    setNodes: (state, action) => {
      state.divisions = { ...state.divisions, ...action.payload };
    },
    getUserCompetedQuery: () => {},
    setUserCompetedQuery: (state, action) => {
      state.completedQuery = action.payload;
    },
  },
});

export const apiSelector = (state) => state.api;

export const {
  startSalesSearch,
  startInfoSearch,
  startPlantSearch,
  startNodeSearch,
  successInfoSearch,
  setGlobalFilters,
  applyStandardFilters,
  applyGlobalFilters,
  setStandardFilters,
  setStandardExpandedFilters,
  clearStandardFilters,
  setQueryHistory,
  getAllFilters,
  setSales,
  setPlants,
  setNodes,
  setLoading,
  toRedirect,
  setParams,
  setNotification,
  deleteStandardFilters,
  deleteGlobalFilters,
  getUserCompetedQuery,
  setUserCompetedQuery,
} = authSlice.actions;
export default authSlice.reducer;
