import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../components/auth/auth.redux";
import apiReducer from "../components/api/api.redux";
import pbiReducer from "../components/reports/pbi.redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./saga";

const configureStoreWithMiddlewares = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      auth: authReducer,
      api: apiReducer,
      pbi: pbiReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(sagaMiddleware),
    // .concat(showApi.middleware)
    // .concat(bandApi.middleware),
    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStoreWithMiddlewares();
