import { createSlice } from "@reduxjs/toolkit";

export const pbiSlice = createSlice({
  name: "pbi",
  initialState: {
    filters: [],
    filtersToRestore: false,
    error: null,
    isRendered: false,
  },
  reducers: {
    setPbiFilters: (state, action) => {
      state.filters =
        action.payload !== null
          ? [
              ...state.filters.filter((p) => p.page !== action.payload.page),
              action.payload,
            ]
          : [];
      state.filtersToRestore = false;
    },
    initPbiSession: (state) => {
      state.filtersToRestore = true;
      state.isRendered = false;
    },
    setPbiError: (state, action) => {
      state.error = action.payload;
    },
    setPbiRendered: (state) => {
      state.isRendered = true;
      state.error = null;
    },
  },
});

export const pbiSelector = (state) => state.pbi;

export const { setPbiFilters, initPbiSession, setPbiError, setPbiRendered } =
  pbiSlice.actions;
export default pbiSlice.reducer;
