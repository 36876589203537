import { takeLatest, select, take } from "@redux-saga/core/effects";
import { setPBI, setUser, requestPbiJwt } from "./auth.redux";
import { all, call, put } from "redux-saga/effects";
import { callADDforToken } from "../../utils/msal.service";
import { requestJWT, setJWT, authSelector, setPhoto } from "./auth.redux";
import { getGraphToken } from "../../utils/axios.service";
import axios from "axios";

export function* awaitGraphToken() {
  let { jwt } = yield select(authSelector);

  // TODO: check if expired
  if (!jwt) {
    yield put(requestJWT());
    const { payload: token } = yield take(setJWT);
    jwt = token;
  }
  return jwt;
}

const extractPhoto = (photo) => {
  if (photo === null) {
    return null;
  }
  window.URL = window.URL || window.webkitURL;
  return window.URL.createObjectURL(photo);
};

export function* requestAadAsync() {
  const aadRequest = yield call(callADDforToken);
  yield put(setPBI(aadRequest.accessToken));
}

export function* requestGraphPhoto() {
  const jwt = yield call(awaitGraphToken);
  try {
    const graphRequest = yield call(
      axios.get,
      `https://graph.microsoft.com/v1.0/me/photo/$value`,
      {
        headers: {
          Authorization: `Bearer ${yield call(getGraphToken, jwt)}`,
        },
        responseType: "blob",
      }
    );

    yield put(setPhoto(extractPhoto(graphRequest.data)));
  } catch {}
}

export function* graphSaga() {
  yield takeLatest(setUser, requestGraphPhoto);
}

export function* aadSaga() {
  yield takeLatest([setUser, requestPbiJwt], requestAadAsync);
}

export function* authSagas() {
  yield all([call(aadSaga), call(graphSaga)]);
}
